
const state = ref(false)

const isSidebarOpen = computed(() => state.value)

const toggleSidebar = () => {
  state.value = !state.value
}
const closeSidebar = () => {
  state.value = false
}

const openSidebar = () => {
  state.value = true
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSidebar = () => ({
  isSidebarOpen,
  toggleSidebar,
  closeSidebar,
  openSidebar
})
